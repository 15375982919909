import {
  TextField,
  Typography,
  Select,
  FormControlLabel,
  Radio,
  InputLabel,
  FormHelperText,
  MenuItem,
  FormControl,
  RadioGroup,
  FormLabel,
  Checkbox,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { isValid } from 'date-fns';
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded';
import { Box } from '@mui/system';
import { FastField, Field, ErrorMessage } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';

// TODO: Styles to the input element shouldn't be applied here, so they can be changed in the components that they are used in.

const customInputStyles = {
  width: '100%',
  marginBottom: '1rem',
};
const errorMessageStyles = {
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.66',
  textAlign: 'left',
  marginRight: '14px',
  marginBottom: '1rem',
  color: '#d32f2f',
};
const radioLabelStyles = {
  fontWeight: '900',
  fontSize: '1.125rem',
  lineHeight: '140%',
  color: 'grey.black',
};

///input type Text
export const CustomTextField = (props) => {
  const { name, label, requiredStar = true, type = 'text', ...rest } = props;
  return (
    <>
      <Field name={name}>
        {({ field, form }) => {
          return (
            <TextField
              autoComplete='off'
              type={type}
              sx={customInputStyles}
              label={
                label && (
                  <Box>
                    {requiredStar ? (
                      <Typography as='span' sx={{ color: 'brand.primaryDark' }}>
                        *{' '}
                      </Typography>
                    ) : null}
                    {label}
                  </Box>
                )
              }
              {...field}
              {...rest}
              error={
                form.touched[name] == true && form.errors[name] !== undefined
              }
              helperText={form.touched[name] && form.errors[name]}
            />
          );
        }}
      </Field>
    </>
  );
};
//////
///Phone number
export const PhoneNumber = (props) => {
  const { t } = useTranslation('common');
  const { name, label, ...rest } = props;
  // TODO: Correctly handle the Phone Number field errors
  // const validatePhone = (value) => {
  //   let error;
  //   if (!matchIsValidTel(value)) {
  //     error = t('invalid_number_error');
  //   }
  //   return error;
  // };
  return (
    <>
      {/* <FastField name={name} validate={validatePhone}> */}
      <FastField name={name}>
        {({ field, form }) => {
          return (
            <MuiTelInput
              {...field}
              {...rest}
              error={
                form.touched[name] == true && form.errors[name] != undefined
              }
              sx={customInputStyles}
              preferredCountries={['SA', 'JO']}
              defaultCountry='SA'
              forceCallingCode
              disableFormatting
              value={field.value}
              onChange={(newValue) => {
                form.setFieldValue([field.name], newValue);
                form.setTouched({ ...form.touched, [field.name]: true });
              }}
              autoComplete='off'
              onBlur={(event) => {
                form.setTouched({ ...form.touched, [field.name]: true });
              }}
              label={
                label && (
                  <Box>
                    <Typography as='span' sx={{ color: 'brand.primaryDark' }}>
                      *{' '}
                    </Typography>{' '}
                    {label}
                  </Box>
                )
              }
            />
          );
        }}
      </FastField>
      <ErrorMessage name={name}>
        {(message) => (
          <Typography sx={errorMessageStyles}>{message}</Typography>
        )}
      </ErrorMessage>
    </>
  );
};
/////Select
export const InputSelect = (props) => {
  const {
    name,
    label,
    requiredStar = true,
    callback = () => {},
    options,
    menuItemStyle = {},
    ...rest
  } = props;
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl sx={customInputStyles}>
          <CustomInputLabel requiredStar={requiredStar} inputLabel={label} />
          <Select
            {...field}
            {...rest}
            onChange={(event) => {
              form.setFieldValue([field.name], event.target.value);
              callback(event.target.value);
            }}
            value={options?.length > 0 ? field.value : ''}
            error={
              form.touched[name] == true && form.errors[name] !== undefined
            }
            label={
              label && (
                <Box>
                  {requiredStar ? (
                    <Typography as='span' sx={{ color: 'brand.primaryDark' }}>
                      *{' '}
                    </Typography>
                  ) : null}
                  {label}
                </Box>
              )
            }
          >
            {options?.map((option) => (
              <MenuItem
                sx={menuItemStyle}
                key={option?.id}
                value={option?.id}
                disabled={
                  option?.permission != undefined && !option?.permission
                }
              >
                {option?.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ ...errorMessageStyles, mt: '3px', mb: '0px' }}>
            {form.touched[name] && form.errors[name]}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};
//////Multi Select
export const MultiSelect = (props) => {
  const {
    name,
    label,
    requiredStar = true,
    callback = () => {},
    options,
    menuItemStyle = {},
    maxDisplayItems = 2,
    ...rest
  } = props;

  const getNameById = (id) => {
    const foundOption = options.find((option) => option.id === id);
    return foundOption ? foundOption.name : '';
  };

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <FormControl sx={customInputStyles}>
          <CustomInputLabel requiredStar={requiredStar} inputLabel={label} />
          <Select
            {...field}
            {...rest}
            multiple
            value={field.value || []}
            onChange={(event) => {
              const value = event.target.value;
              form.setFieldValue(
                name,
                typeof value === 'string' ? value.split(',') : value
              );
              callback(value);
            }}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => {
              if (selected.length > maxDisplayItems) {
                return `${selected
                  .slice(0, maxDisplayItems)
                  .map(getNameById)
                  .join(', ')}...`;
              }
              return selected.map(getNameById).join(', ');
            }}
            error={meta.touched && meta.error !== undefined}
          >
            {options?.map((option) => (
              <MenuItem key={option.id} value={option.id} sx={menuItemStyle}>
                <Checkbox
                  checked={
                    Array.isArray(field.value) &&
                    field.value.indexOf(option.id) > -1
                  }
                />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ ...errorMessageStyles, mt: '3px', mb: '0px' }}>
            {meta.touched && meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

//////
export const CustomSelect = (props) => {
  const { name, inputLabel, requiredStar = false, children, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <>
            <Select
              {...rest}
              {...field}
              autoComplete='off'
              error={form.touched[name] && form.errors[name] != undefined}
            >
              {children}
            </Select>
            {form.touched[name] && (
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {form.errors[name]}
              </FormHelperText>
            )}
          </>
        );
      }}
    </Field>
  );
};
/////
//Input Date
export const InputDate = (props) => {
  const { name, label, requiredStar = true, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <Field
      name={name}
      validate={(value) => {
        let dateValue = new Date(value); //because the value could be string
        let error;
        if (!isValid(dateValue)) error = t('invalid_date');
        return error;
      }}
    >
      {({ field, form }) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <DatePicker
              {...field}
              {...rest}
              disableFuture={true}
              inputFormat='dd/MM/yyyy'
              onChange={(newValue) => {
                form.setFieldValue([field.name], newValue);
                form.setTouched({ ...form.touched, [field.name]: true });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <Box>
                      {requiredStar ? (
                        <Typography
                          as='span'
                          sx={{ color: 'brand.primaryDark' }}
                        >
                          *{' '}
                        </Typography>
                      ) : null}
                      {label}
                    </Box>
                  }
                  error={form.touched[name] && form.errors[name] != undefined}
                  autoComplete='off'
                  sx={customInputStyles}
                />
              )}
            />
            {form.touched[name] && (
              <FormHelperText sx={errorMessageStyles}>
                {form.errors[name]}
              </FormHelperText>
            )}
          </Box>
        );
      }}
    </Field>
  );
};
//////
//Input Time
//Input Date
export const InputTime = (props) => {
  const { name, label, requiredStar = true, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <Field
      name={name}
      validate={(value) => {
        let dateValue = new Date(value); //because the value could be string
        let error;
        if (!isValid(dateValue)) error = t('invalid_time');
        return error;
      }}
    >
      {({ field, form }) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <TimePicker
              {...field}
              {...rest}
              inputFormat='hh:mm a'
              disableMaskedInput
              ampm={true}
              onChange={(newValue) => {
                form.setFieldValue([field.name], newValue);
                form.setTouched({ ...form.touched, [field.name]: true });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <Box>
                      {requiredStar ? (
                        <Typography
                          as='span'
                          sx={{ color: 'brand.primaryDark' }}
                        >
                          *{' '}
                        </Typography>
                      ) : null}
                      {label}
                    </Box>
                  }
                  error={form.touched[name] && form.errors[name] != undefined}
                  autoComplete='off'
                  sx={customInputStyles}
                />
              )}
            />
            {form.touched[name] && (
              <FormHelperText sx={errorMessageStyles}>
                {form.errors[name]}
              </FormHelperText>
            )}
          </Box>
        );
      }}
    </Field>
  );
};
/////
///input Radio
export const InputRadio = (props) => {
  const { name, label, options, ...rest } = props;
  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <>
            <FormLabel sx={radioLabelStyles}>
              <Typography as='span' sx={{ color: 'brand.primaryDark' }}>
                *{' '}
              </Typography>
              {label}
            </FormLabel>
            <RadioGroup
              name='image'
              defaultValue={field.value}
              sx={customInputStyles}
              row
              {...field}
              {...rest}
            >
              {options?.map((option) => (
                <FormControlLabel
                  key={option?.id}
                  type='radio'
                  value={option.value}
                  label={option.label}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: 'brand.secondaryDark',
                        },
                      }}
                    />
                  }
                />
              ))}
            </RadioGroup>
          </>
        );
      }}
    </Field>
  );
};

// Image Component
export const InputImage = (props) => {
  const { name, ...rest } = props;
  return (
    <Field name={name} sx={{ position: 'relative' }}>
      {({ field, form }) => (
        <Box>
          {!field.value ? (
            <>
              <input
                {...field}
                {...rest}
                hidden
                onChange={(event) => {
                  form.setFieldValue(
                    [field.name],
                    event.currentTarget.files[0]
                  );
                  form.setTouched({ ...form.touched, [field.name]: true });
                }}
                onBlur={(event) => {
                  form.setTouched({ ...form.touched, [field.name]: true });
                }}
                type='file'
                accept='image/*'
                id='upload-file'
              />
              <label htmlFor='upload-file' style={{ maxWidth: '100px' }}>
                <Box
                  sx={{
                    display: 'inline-block',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <InsertPhotoRoundedIcon
                    sx={{
                      fontSize: '6.25rem',
                      color: '#00000061',
                    }}
                  />
                  <AddIcon
                    sx={{
                      position: 'absolute',
                      top: '1px',
                      right: '7%',
                      backgroundColor: '#F5F8FA',
                      borderRadius: '50%',
                    }}
                  />
                </Box>
              </label>{' '}
            </>
          ) : (
            <Box sx={{ position: 'relative', width: 'fit-content' }}>
              <ClearIcon
                onClick={() => {
                  form.setFieldValue([field.name], '');
                }}
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-11px',
                  backgroundColor: '#f5f8fa',
                  borderRadius: '1rem',
                  fontSize: '1.5rem',
                  zIndex: '3',
                  cursor: 'pointer',
                }}
              />
              <Image
                src={
                  typeof field?.value === 'string'
                    ? field?.value
                    : URL.createObjectURL(field?.value)
                }
                alt='Uploaded Image preview'
                width={100}
                height={100}
              />
            </Box>
          )}
          <ErrorMessage name={name}>
            {(message) => (
              <Typography sx={errorMessageStyles}>{message}</Typography>
            )}
          </ErrorMessage>
        </Box>
      )}
    </Field>
  );
};

///File Upload
export const InputFileUpload = ({ name, types, label, ...rest }) => {
  return (
    <Field name={name} sx={{ position: 'relative' }}>
      {({ field, form }) => (
        <Box>
          <input
            hidden
            type='file'
            id={name}
            name={name}
            types={types}
            onChange={(event) => {
              form.setFieldValue(name, event.target.files[0]);
            }}
            {...rest}
          />
          <Box sx={{ display: 'flex' }}>
            <label htmlFor={name} style={{ maxWidth: '100px' }}>
              <Box
                sx={{
                  display: 'inline-block',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <InsertDriveFileRoundedIcon
                  sx={{
                    fontSize: '6.25rem',
                    color: '#00000061',
                  }}
                />
                {field.value ? (
                  <ClearIcon
                    onClick={(event) => {
                      event.preventDefault();
                      form.setFieldValue([field.name], '');
                    }}
                    sx={{
                      position: 'absolute',
                      top: '1px',
                      right: '7%',
                      backgroundColor: '#F5F8FA',
                      borderRadius: '50%',
                      zIndex: '3',
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      position: 'absolute',
                      top: '1px',
                      right: '7%',
                      backgroundColor: '#F5F8FA',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </Box>
            </label>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '0.5rem',
              }}
            >
              <Typography sx={{ color: 'grey.dark' }}>
                {field?.value && field?.value?.name}
              </Typography>
            </Box>
          </Box>
          <ErrorMessage name={name}>
            {(message) => (
              <Typography component='p' sx={errorMessageStyles}>
                {message}
              </Typography>
            )}
          </ErrorMessage>
        </Box>
      )}
    </Field>
  );
};
////////////////////////////////////////////////
const CustomInputLabel = ({ requiredStar, inputLabel }) => {
  return (
    <>
      <InputLabel>
        {requiredStar ? (
          <Typography as='span' sx={{ color: 'brand.primaryDark' }}>
            *
          </Typography>
        ) : null}{' '}
        {inputLabel}
      </InputLabel>
    </>
  );
};
