import {
  CustomTextField,
  InputDate,
  InputFileUpload,
  InputImage,
  InputRadio,
  InputSelect,
  MultiSelect,
  InputTime,
  PhoneNumber,
} from './formComponents';
function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case 'inputText':
      return <CustomTextField {...rest} />;
    case 'phoneNumber':
      return <PhoneNumber {...rest} />;
    case 'inputSelect':
      return <InputSelect {...rest} />;
    case 'multiSelect':
      return <MultiSelect {...rest} />;
    case 'inputDate':
      return <InputDate {...rest} />;
    case 'inputTime':
      return <InputTime {...rest} />;
    case 'inputRadio':
      return <InputRadio {...rest} />;
    case 'inputImage':
      return <InputImage {...rest} />;
    case 'inputFileUpload':
      return <InputFileUpload {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
